import React from "react"
import { Card } from "react-bootstrap";

const HomePage = () => {
  return (
    <>
    <center><h1>North East Indian Church USA </h1></center>
    <Card style={{ width: '80%', border: 0 }} >
      <Card.Body>
        <Card.Title>The Vision</Card.Title>
        <Card.Text>
          To reach the unchurched and unreached people of Northeast India in the Bay Area and Northern California
        </Card.Text>
      </Card.Body>
    </Card>
    <Card style={{ width: '80%', border: 0 }} >
      <Card.Body>
        <Card.Title>The Mission</Card.Title>
        <Card.Text>
          To love one another and our neighbors and spread the good news of the kingdom of God locally and globally
        </Card.Text>
      </Card.Body>
    </Card>

     <div className="App">
      <header className="App-headerx">
        <img src="/images/nei.jpg" alt="logo"/>
      </header>
    </div>
    </>
  )
};
export default HomePage;