import { platformConfig } from './config/platform';

const setHeaders = async (form) => {
  // let token = null;
  // token = await (Auth.currentUser && Auth.currentUser.getIdToken().catch((e)=>{debugLog("Token error: ", e)})) || "notset";
    // 'Authorization': `Bearer ${token}`,
  return {
    'App-Version': global.app_version || '0.0.1',
    'Content-Type': (form ? 'multipart/form-data' : 'application/json')
  };
};

const processFetch = (fetchResponse) => {
  return new Promise((resolve,reject) => {
    fetchResponse
    .then((response)=>{
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then((json) => {
          if( response.ok ) {
            return resolve(json);
          } else {
            return reject( json );
          }        
        });
        return;
      } 
      
      response.text()
      .then((data)=>{
        if( response.ok ) {
          return resolve(data)
        } else {
          return reject(
            {
              code: response.status, 
              type: response.statusText, 
              error: response.statusText
            }
          );
        }
      })
    })
    .catch((errorObject) =>{
      return reject(
        {code: 500, type: 'servererror', message: 'Network request failed'}
      );
    });

  });
};

//So path.resolve doesn't work well. If you add /xxx the all hell breaks loose.
const fullPath = (urlPath) => {
  let path;
  if(urlPath.charAt(0) === '/') {
    path = `${platformConfig.apiUrl}${urlPath}`;
  } else {
    path = `${platformConfig.apiUrl}/${urlPath}`;
  }
  return path;
}

export const Get = async (UrlPath) => {
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'GET',
      headers: await setHeaders(false)
    })
  );  
}

export const Post = async (UrlPath, postData, form=false) => {
  const data = form ? postData : JSON.stringify(postData);
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'POST',
      headers: await setHeaders(form),
      body: data,
    })

  );  
}

export const Put = async (UrlPath, postData, form=false) => {
  const data = form ? postData : JSON.stringify(postData);
  console.log("PUT: ", data)
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'PUT',
      headers: await setHeaders(form),
      body: data,
    })

  );  
}

export const Delete = async (UrlPath) => {
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'DELETE',
      headers: await setHeaders(false)
    })

  );  
}