import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Container, Nav } from 'react-bootstrap';
import AboutPage from "./pages/About";
import NotFoundPage from "./pages/NotFound";
import HomePage from "./pages/Home";
import NeedPage from "./pages/Need";
import PlanPage from "./pages/Plan";
import Events from "./Events";
import Login from "./Login";

function App() {
  return (
    <Container>
      <Nav className="naga" fill variant="tabs" defaultActiveKey="/home">
        <Nav.Item>
          <Nav.Link href="/">Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/needs">The Need</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/plans">The Plan</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/about">About</Nav.Link>
        </Nav.Item>
       </Nav>
       <br/>
      <Routes>
        <Route path="/" element={ <HomePage /> } />
        <Route path="/needs" element={<NeedPage />} />
        <Route path="/plans" element={<PlanPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/events" element={<Events />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Container>
  );
}

export default App;
