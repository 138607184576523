const Cookie = class {
// eslint-disable-next-line 
  constructor(name?: string, value?: any, minutesToExpire?: number) {
    this._name = name || null;
    this._value = value || null;
    this._minutesToExpire = minutesToExpire || 60;
  }
  _name: string|null;
  _value: any|null;
  _minutesToExpire: number|null;


  get (name?: string) {
    if(!name && !this._name) return null;
    let cName = name || this._name;
    let cookies = document.cookie.split(';');
    for(var i=0; i<cookies.length; i++) {
      let pos = cookies[i].indexOf('=');
      let cookieName = cookies[i].substring(0,pos).trim();
      let cookieValue = cookies[i].substring(pos+1) ;
      // console.log("Cookie: " + cookies[i] + " name:" + cookieName + " value: " + cookieValue)
      if( cookieName === cName ) {
        return cookieValue;
      }
    }
    return null;
  }

  set (name?: string, value?: any, minutesToExpire?: number) {
    if(!name && !this._name) return null;
    let cName = name || this._name;
    let cValue = value || this._value;
    let cMins = minutesToExpire || this._minutesToExpire;

    let date = new Date();
    let expSeconds = (cMins || 60) * 60 * 1000;
    date.setTime(date.getTime()+expSeconds);

    let cookie=cName+"="+cValue+"; expires="+date.toUTCString();
    console.log("Set cookie:" + cookie);
    document.cookie=cookie;
    return cName;
  }

  delete (name?: string) {
    if(!name && !this._name) return null;
    let cName = name || this._name;
    let cookie=cName+"=; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = cookie;
    return true;
  }

}

export default Cookie;
