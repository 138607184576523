import React from "react";
// import { Card } from "react-bootstrap";

const Events = () => {
  return (
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>

    // <Card style={{ width: '18rem', height: '410px' }}>
    // <Card.Body>
      // <Card.Title>Test Title</Card.Title>
    // </Card.Body>
    // </Card>
  );
}

export default Events;