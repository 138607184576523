import React from "react"
import { ListGroup } from "react-bootstrap"

const PlanPage = () => {
  return (
    <>
    <center><h1>Church Planting & Growth Strategy</h1></center>
    <br/>
    <ListGroup as="ol" numbered>
      <ListGroup.Item as="li" className="naga d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
          <div className="fw-bold">Children: The Children's ministry will be the growth engine</div>
          The Northeast Indian families who live in the Bay Area and Northern California have 2-4 children in each family. We will offer programs unique to children that non-ethnic churches cannot offer.
        </div>
      </ListGroup.Item>
      <ListGroup.Item as="li" className="naga d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
          <div className="fw-bold">Youth: A Compelling Youth Program</div>
          Create a youth program where East meet West
        </div>
      </ListGroup.Item>
      <ListGroup.Item as="li" className="naga d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
          <div className="fw-bold">College/Students: Culture of Serving</div>
          Create opportunities for college-age students to serve in the church
        </div>
      </ListGroup.Item>
      <ListGroup.Item as="li" className="naga d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
          <div className="fw-bold">Families</div>
          The Northeast Indian Church will reach out to the Northeast families and friends who do not have a church
          <br/>
          <br/>
          The Northeast Indian Church will reach out to the Northeast Indians who live in the Bay area and who do not know Jesus Christ. There are many non-Christian families living here
        </div>
      </ListGroup.Item>
    </ListGroup>
    </>
  )
};
export default PlanPage;