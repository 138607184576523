import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { Card } from "react-bootstrap";
import Cookie from "./functions/Cookie";
import {Post} from "./platform/Server";

const Login = () => {
  const [error, setError] = useState("");
  const [reCapchaToken, setReCapchaToken] = useState("");
  const [reCaptchaExpired, setreCaptchaExpired] = useState(false);
  const [show, setShow] = useState(false);

  const onChange = (token: string | null) => {
    if( token && token.length > 255 ) {
      setReCapchaToken(token || "");
      setreCaptchaExpired(false)
      setError("")
      setShow(true);
    }
  }

  const onExpired = () => {
    setreCaptchaExpired(true);
    setShow(false);
    setError("ReCaptcha expired. Please complete again.");
  }

  const onError = () => {
    setreCaptchaExpired(true);
    setReCapchaToken("");
    setShow(false)
    setError("There was an error with ReCaptcha. Please refresh page.");
  }

  // const serverTest = () => {
  //   Post('sessions/nei', {test: 'asdasdf'})
  //   .then((resp) => {
  //     new Cookie("neiSession", resp["session"]).set();
  //     setError("");
  //   }).catch((resp)=>{
  //     let err = resp['error'] || "Please try again."
  //     setError("There was an error with login. " + err);
  //   })
  // }

  const serverLogin = (googleToken: CredentialResponse ) => {
    let tokens = {code: googleToken.credential, recaptcha_token: reCapchaToken}
    Post('sessions/nei', tokens)
    .then((resp) => {
      new Cookie("neiSession", resp["session"]).set();
      setError("Welcome " + resp["name"] + "(" + resp["email"] + ")");
    }).catch((resp)=>{
      let err = resp['error'] || "Please try again."
      setError("There was an error with login. " + err);
    })
  }

  const responseMessage = (response: CredentialResponse) => {
    serverLogin(response);
  };

  const errorMessage = () => {
    setError("Error logging into Google")
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: tokenResponse => serverLogin(tokenResponse),
  //   flow: 'auth-code',
  //   redirect_uri: "http%3A%2F%2Flocalhost%3A3000%2Fsessions%2Fgoogle%2Fcallback",
  //   onError: () => errorMessage()
  // });

  // const login = () => {
  //   if( reCaptchaExpired || reCapchaToken.length <= 255 ) {
  //     return window.alert("Please complete ReCaptcha!")
  //   }
  //   return googleLogin();
  // }

  return(
    <>
    {error && <h4 style={{color:'red'}}>{error}</h4> }
      <Card>
        <Card.Body>
          <Card.Title>Login with Google</Card.Title>

          {/* <Button  onClick={()=>serverTest()}> Sign in with google </Button> */}
          <ReCAPTCHA 
            sitekey={process.env.REACT_APP_RECAPTCHA_V2_KEY || ""} 
            onChange={onChange}
            onExpired={onExpired}
            onErrored={onError}
            theme="light"
        />

        {(show && !reCaptchaExpired) && <GoogleLogin onSuccess={responseMessage} useOneTap={false} nonce={Date.now.toString()} onError={errorMessage} /> }
      </Card.Body>
    </Card>
    </>
  )

}

export default Login;
